@use "./styles-utils.scss" as *;

.AssetAmount {
  display: inline-flex !important;
  align-items: center;
  gap: pxToRem(4px);

  &__icon {
    display: block;
    width: pxToRem(12px);
    height: pxToRem(12px);
    border-radius: pxToRem(6px);
    background-color: var(--color-gray-30);
    border: 1px solid var(--color-gray-30);
    overflow: hidden;
    flex-shrink: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
