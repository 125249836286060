@use "./styles-utils.scss" as *;

.DropdownMenu {
  display: flex;
  flex-direction: column;
  gap: pxToRem(4px);
  padding: pxToRem(4px);

  &__item {
    display: block;
    width: pxToRem(168px);
    padding: pxToRem(4px) pxToRem(8px);
    border-radius: pxToRem(4px);
    background-color: var(--color-gray-00);
    text-decoration: none;
    color: var(--color-gray-80);
    font-size: pxToRem(14px);
    line-height: pxToRem(22px);
    font-weight: var(--font-weight-medium);
    transition: all var(--anim-transition-default);
    cursor: pointer;

    &--highlight {
      color: var(--color-red-60);

      &[aria-disabled="true"] {
        color: var(--color-red-50);
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-gray-10);

        &[aria-disabled="true"] {
          background-color: var(--color-gray-00);
        }
      }
    }

    &--current {
      background-color: var(--color-gray-10);
      cursor: default;
    }

    &[aria-disabled="true"] {
      cursor: not-allowed;
    }
  }
}
