@use "./styles-utils.scss" as *;

.PageHeader {
  display: flex;
  position: relative;
  z-index: calc(var(--z-index-tooltip) + 1);

  &__content {
    flex: 1;
  }

  &__inset {
    height: pxToRem(32px);
    margin-top: pxToRem(16px);
    margin-bottom: pxToRem(16px);
    display: flex;
    align-items: center;
    gap: pxToRem(12px);
    padding: 0 pxToRem(32px);
  }

  &__logo {
    display: block;
    margin-left: pxToRem(-8px);
    height: 100%;
    min-width: pxToRem(calc(242px - (28px * 2)));
    flex-shrink: 0;

    svg {
      display: block;
      fill: var(--color-gray-90);
      height: 100%;
      width: 100%;
    }
  }

  &--left,
  &--right {
    display: flex;
    align-items: center;
    flex: 1;
    gap: pxToRem(12px);
  }

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &--internal {
    border-bottom: 1px solid var(--color-gray-40);
  }
}

// Company brand
.CompanyBrand {
  display: flex;
  align-items: center;
  gap: pxToRem(12px);
  height: pxToRem(32px);

  &__logo {
    height: pxToRem(32px);
    min-width: pxToRem(32px);
    border-radius: pxToRem(16px);
    background-color: var(--color-gray-00);
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    position: relative;

    background-size: cover;
    background-position: center center;

    img {
      height: 100%;
      display: block;
      position: absolute;
    }
  }
  &__name {
    font-size: pxToRem(14px);
    line-height: pxToRem(22px);
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-70);
    flex: 1;
  }
}

// Datetime
.DateTime {
  display: flex;
  align-items: center;
  gap: pxToRem(8px);
  font-size: pxToRem(14px);
  line-height: pxToRem(22px);
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-60);

  &__icon {
    display: block;
    width: pxToRem(14px);
    height: pxToRem(14px);

    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
  }
}
