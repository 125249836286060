@use "./styles-utils.scss" as *;

.ReceiverInviteMessage {
  &__options {
    .RadioButton {
      --RadioButton-font-size: #{pxToRem(14px)};
    }

    display: flex;
    flex-direction: column;
    gap: pxToRem(8px);
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: pxToRem(16px);

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: pxToRem(8px);
    }
  }

  .Note {
    margin-top: 0;
  }
}
