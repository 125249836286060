@use "./styles-utils.scss" as *;

.Breadcrumbs {
  --Link-font-size: pxToRem(14px);
  --Link-line-height: pxToRem(22px);

  display: flex;
  align-items: center;
  gap: pxToRem(4px);
  font-weight: var(--font-weight-medium);
  margin-bottom: pxToRem(16px);
  color: var(--color-gray-60);

  &__separator {
    display: block;
    width: pxToRem(14px);
    height: pxToRem(14px);

    svg {
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
  }
}
