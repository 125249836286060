@use "./styles-utils.scss" as *;

.CopyWithIcon {
  --CopyWithIcon-icon-size: 0;

  display: inline-flex;
  align-items: center;
  gap: 0.4em;

  &__icon {
    display: flex;
    align-items: center;

    svg {
      display: block;
      width: 80%;
      height: 80%;
      fill: var(--color-gray-50);
    }
  }
}
