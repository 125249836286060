@use "./styles-utils.scss" as *;

.Notification {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: pxToRem(12px);
    margin-top: pxToRem(8px);

    .Link--primary {
      --Link-color-default: var(--color-gray-90);
      --Link-color-hover: var(--color-gray-80);
      --Link-color-disabled: var(--color-gray-70);
    }
  }
}
