@use "./styles-utils.scss" as *;

.InnerPage {
  display: flex;
  height: calc(100vh - pxToRem(65px));

  &__sidebar {
    width: pxToRem(242px);
    flex-shrink: 0;
    flex-grow: 0;
    border-right: 1px solid var(--color-gray-40);
    display: flex;
    flex-direction: column;
    overflow: auto;

    &--top,
    &--bottom {
      padding: pxToRem(32px);
      display: flex;
      flex-direction: column;
      gap: pxToRem(12px);
    }

    &--top {
      flex: 1;
    }

    &--bottom {
      flex: 1;
      justify-content: flex-end;
    }
  }

  &__container {
    flex: 1;
    overflow: auto;
  }

  &__content {
    padding: pxToRem(32px);
    color: var(--color-gray-90);
    width: 100%;
    max-width: pxToRem(2000px);
    margin-left: auto;
    margin-right: auto;

    &--narrow {
      width: pxToRem(584px);
      padding-left: 0;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.Sidebar {
  &__navItem {
    display: flex;
    align-items: center;
    gap: pxToRem(16px);
    font-size: pxToRem(14px);
    line-height: pxToRem(22px);
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-60);
    text-decoration: none;
    transition: all var(--anim-transition-default);

    &__icon {
      display: block;
      width: pxToRem(14px);
      height: pxToRem(14px);

      svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: currentColor;
      }
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gray-90);
      }
    }

    &--current {
      color: var(--color-gray-90);
      cursor: default;
    }
  }

  &__item {
    font-size: pxToRem(10px);
    line-height: pxToRem(16px);
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-gray-60);
  }
}
